import $ from 'jquery';

export default {
  namespaced: true,
  state: {
    save: {}, // Wird dynamisch aus Settings geladen
    isSaving: false,
    showNavigation: false,
    alerts: [],
    ajaxMaxTimeout: 5000,
    routerCurrentFeature: '',
    inputValidationRules: {
      // Inhalt darf nicht leer sein:
      required: (value) => !!value || 'Bitte angeben.',
      // Inhalt darf nicht lang sein:
      tinytext: (value) => value.length <= 25 || 'Maximal 25 Zeichen sind erlaubt',
      // Inhalt darf kaum Sonderzeichen enthalten:
      nutzername: (value) => {
        const pattern = /^[A-Za-z0-9\-_.äöüÄÖÜß]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf kaum Sonderzeichen enthalten:
      name: (value) => {
        const pattern = /^[A-Za-z0-9 \-_.,äöüÄÖÜß]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf auch keine Umlaute oder Leerzeichen enthalten:
      nameLess: (value) => {
        const pattern = /^[A-Za-z0-9\-_]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf mehr Sonderzeichen enthalten:
      nameMore: (value) => {
        const pattern = /^[A-Za-z0-9 +,()\-_.äöüÄÖÜß]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf alle Sonderzeichen außer " enthalten:
      pw: (value) => {
        const pattern = /^[A-Za-z0-9 \-_.äöüÄÖÜß!$%^&*() +|~=`\\#{}[\]:;<>²³°'´?,/]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt muss mindestens 8 Zeichen enthalten:
      minPW: (value) => value.length >= 8 || 'Mindestens 8 Zeichen angeben',
      // Inhalt darf nur ein deutsches Datum enthalten:
      datum: (value) => {
        const pattern = /(^$)|(\b[0-9]{1,2}[.][0-9]{1,2}[.][0-9]{4}\b)/;
        return pattern.test(value) || 'Bitte im folgenden Format angeben: TT.MM.YYYY';
      },
      // Inhalt darf nur Ziffern enthalten:
      nummer: (value) => {
        const pattern = /^s*|[0-9]*/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf nur Koordinaten enthalten:
      koordinaten: (value) => {
        const pattern = /^s*|[0-9]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf nur Sonderzeichen aus Telefonnummern enthalten:
      telefon: (value) => {
        const pattern = /^[0-9 \-/+]*$/;
        return pattern.test(value) || !value || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf alle Sonderzeichen enthalten:
      text: (value) => {
        const pattern = /^[A-Za-z0-9 \-_.äöüÄÖÜß!$%^&*() +|~=`\\#{}[\]:;<>"'?,/]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt darf nur Sonderzeichen für URLs enthalten:
      url: (value) => {
        const pattern = /^[A-Za-z0-9/\-?=&.]*$/;
        return pattern.test(value) || 'Enthält unerlaubte Zeichen';
      },
      // Inhalt muss eine gültige E-Mail Adresse sein:
      email: (value) => {
        const pattern = /((^$)|(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$))/;
        return pattern.test(value) || !value || 'Ungültige E-Mail-Adresse';
      },
    },
  },
  getters: {
    getmainmenulinks: (state) => state.save.mainmenu_links,
  },
  mutations: {
    changeState(state, { name, value }) {
      state[name] = value;
    },
    routerUpdateCurrentFeature(state, newValue) {
      state.routerCurrentFeature = newValue;
    },
    settings_setSettings(state, { loadedSettings, login, router }) {
      if (loadedSettings) {
        if (state.save.isChanged) {
          // console.log('Die Settings wurden bereits lokal verändert.')
          // console.log('Um ein Überschrieben zu verhindern wurde das neu Laden blockiert.');
        } else {
          // console.log('Die Settings wurden neu geladen.');
          /*
            Wenn sich die API-Version von der zuletzt geladenen Version
            unterscheidet, blende die Meldung ein und lade die App neu.
          */
          if (state.save.app_api_version) {
            if (state.save.app_api_version !== loadedSettings.app_api_version) {
              loadedSettings.app_api_update = true;
              document.getElementById('main_loading_update').classList.add('show');
              document.getElementById('manualUpdateInfo').classList.add('show');
              /* setTimeout(() => {
                window.location.reload();
              }, 1001); */
            } else {
              loadedSettings.app_api_update = false;
            }
          }
          state.save = loadedSettings;
          this.commit('main/settings_saveSettings', { loadedSettings });
        }
      }
      if (login) {
        // Wenn nach Login die Settings geladen wurden,
        // führe Login durch
        this.commit('login/login', { router });
      }
      if (state.isSaving === true) {
        setTimeout(() => {
          this.commit('main/alert', {
            typ: 'success',
            text: 'Erfolgreich gespeichert',
          }, { root: true });
          state.isSaving = false;
        }, 200);
      }
    },
    settings_saveSettings(state, { loadedSettings }) {
      localStorage.setItem('app_settings', JSON.stringify(loadedSettings));
    },
    saveIsChanged(state, bool) {
      state.save.isChanged = bool;
    },
    switchIsSaving(state, payload) {
      state.isSaving = payload;
    },
    mainmenu_setLinks(state, { links }) {
      state.save.mainmenu.links = links;
    },
    mainmenu_switchNav(state, payload) {
      state.showNavigation = payload;
    },
    alert_showAlert(state, payload) {
      const alert = {
        id: this.state.main.alerts.length + 1,
        text: payload.text,
        color: payload.color,
        timeout: payload.timeout,
      };
      state.alerts.push(alert);
    },
    alert_delAlert(state, payload) {
      state.alerts.splice(payload.index, 1);
    },
    alert(state, { typ, text, timeout }) {
      let color;
      if (typ === 'success') {
        color = 'success darken-4';
      }
      if (typ === 'error') {
        color = 'error darken-3';
      }
      let pTimeout = 4000;
      if (timeout) {
        pTimeout = timeout;
      }
      this.commit('main/alert_showAlert', { text, color, timeout: pTimeout });
    },
    nutzerparameter_addNutzerparam(state) {
      state.save.nutzerparameter.push({
        id: '',
        icon: 'mdi-tag',
        ident: false,
        name: 'Neuer Parameter',
        selectoptions: [],
        sort: state.save.nutzerparameter.length + 9,
        typ: '1',
        f100: {
          seeOnTable: false,
        },
        bedingungen: {
          f010: {
            sehen: {
              alle: true,
              gruppen: [],
            },
            bearbeiten: {
              alle: true,
              gruppen: [],
            },
          },
          f100: {
            sehen: {
              alle: true,
              gruppen: [],
            },
            bearbeiten: {
              alle: false,
              gruppen: [],
            },
          },
          f200: {
            sehen: {
              alle: true,
              gruppen: [],
              nutzer: true,
            },
            bearbeiten: {
              alle: false,
              gruppen: [],
              nutzer: true,
            },
          },
        },
      });
    },
    nutzerparameter_delNutzerparam(state, { idx }) {
      state.save.nutzerparameter.splice(idx, 1);
    },
    nutzerparameter_updateBedingungen(state, { param, bedingungen }) {
      state.save.nutzerparameter[param].bedingungen = bedingungen;
    },
    nutzergruppen_addNutzergruppe(state) {
      state.save.nutzergruppen.push({
        id: '',
        icon: 'mdi-account-group-outline',
        name: 'Neue Gruppe',
        nutzerparameter: [],
        rechte: [],
        standard: false,
        forcePwReset: false,
        beschreibung: '',
      });
    },
    nutzergruppen_delNutzergrp(state, { idx }) {
      state.save.nutzergruppen.splice(idx, 1);
    },
  },
  actions: {
    async tryDownload({ commit }, { path, name, newTab }) {
      // console.log(path);
      const senddata = {
        code: this.state.login.session.code,
        file: path, // z.B. f200-200/kinderDokumente/IDCard_32.pdf
      };
      await fetch(`${process.env.VUE_APP_API_HOST}/data/download.php`, {
        method: 'POST',
        body: JSON.stringify(senddata),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          if (newTab) {
            a.target = '_blank';
          } else {
            a.download = name;
          }
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => {
          // Wenn bei der Verbindung ein Fehler aufgetreten ist:
          commit('main/alert', {
            typ: 'error',
            text: `Die angeforderte Datei konnte nicht erzeugt werden.
            Bitte die Netzwerkverbindung prüfen.`,
          });
          console.log(error);
        });
    },
    async tryGetSettings({ state, commit }, { code, login, router }) {
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=getSettings`,
        type: 'post',
        data: {
          code,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.commit('login/backendErrorSession');
          } else {
            commit('settings_setSettings', { loadedSettings: response.data, login, router });
          }
        },
        timeout: state.ajaxMaxTimeout,
        error: () => {
          // commit('settings_setSettings', { loadedSettings: null });
        },
      });
    },
    async trySaveSettings({ state, rootState }, { query }) {
      // console.log(query);
      this.commit('main/switchIsSaving', true);
      this.commit('main/settings_saveSettings', { loadedSettings: state.save });
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=saveSettings&query=${query}`,
        type: 'post',
        data: {
          code: rootState.login.session.code,
          data: JSON.stringify(state.save),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.commit('login/backendErrorSession');
          } else {
            // console.log(response);
            this.commit('main/saveIsChanged', false);
            this.dispatch('main/tryGetSettings', { code: rootState.login.session.code });
          }
        },
        timeout: state.ajaxMaxTimeout,
        error: () => {
          this.commit('main/alert', {
            typ: 'error',
            text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.commit('main/switchIsSaving', false);
        },
        complete: () => {
        },
      });
    },
  },
};
